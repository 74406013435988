export default [
    {
        component: 'CNavItem',
        name: 'Till',
        to: '/dashboard/till',
        icon: 'till',
        faIcon: 'fa-cash-register',
    },
    {
        component: 'CNavItem',
        name: 'Direct Debits',
        to: '/dashboard/direct-debits',
        icon: 'till',
        faIcon: 'fa-university',
    },
    {
        component: 'CNavItem',
        name: 'Transactions',
        to: '/dashboard/transactions',
        icon: 'transactions',
        faIcon: 'fa-rectangle-list',
    },
    {
        component: 'CNavItem',
        name: 'Appointments',
        to: '/dashboard/appointments',
        icon: 'transactions',
        faIcon: 'fa-calendar-days',
    },
    {
        component: 'CNavItem',
        name: 'Summary',
        to: '/dashboard/summary',
        icon: 'dashboard',
        faIcon: 'fa-pie-chart',
    },
    {
        component: 'CNavItem',
        name: 'Support',
        to: '/dashboard/support',
        icon: 'support',
        faIcon: 'fa-question',
    },
];
