<template>
    <CCol
        class="p-2 m-2"
        style="border-radius: 20px; border: solid 1px #cfdbd5b2"
    >
        <CRow class="justify-content-center m-2">
            <CFormInput
                v-if="isEditingName"
                v-model="terminal.name"
                id="name"
                ref="name"
                @keyup.enter="updateName()"
                @blur="updateName()"
            ></CFormInput>
            <p
                class="clickable"
                @click="
                    isEditingName = true;
                    focusInputField('name');
                "
                v-if="isEditingName === false"
            >
                <span v-if="terminal.name">
                    {{ terminal.name }}
                </span>
                <span
                    style="
                        font-style: italic;
                        border-bottom: dashed 1px;
                        background-color: #efd9a3;
                    "
                    v-else
                >
                    {{ terminal.id }}
                    <font-awesome-icon
                        icon="fa-solid fa-pen-to-square"
                        class="ms-1"
                    />
                </span>
            </p>
        </CRow>
        <CRow v-if="terminalTransaction">
            <p>
                Current: {{ terminalTransaction.name }}
                <br />
                {{ renderCurrency(terminalTransaction.amount) }} -
                {{ renderStatus(terminalTransaction.status) }}
            </p>
        </CRow>
        <CRow v-if="completed">
            <CButton class="m-3 btn-outline-dark" disabled>
                <font-awesome-icon icon="fa fa-circle-check" size="lg" />
            </CButton>
        </CRow>
        <CRow class="justify-content-center" v-else>
            <CCol class="col-sm-10 col-md-6 col-lg-4">
                <CRow>
                    <CButton
                        class="btn-outline-dark mb-1"
                        @click="sendToTerminal()"
                        :disabled="sendingToTerminal"
                    >
                        <template v-if="sendingToTerminal">
                            <font-awesome-icon
                                icon="fa fa-circle-notch"
                                class="clickable me-2"
                                spin
                            />
                            Sending
                        </template>
                        <template v-else>
                            <font-awesome-icon
                                icon="fa fa-paper-plane"
                                class="clickable me-2"
                                size="lg"
                            />
                            Send
                        </template>
                    </CButton>
                </CRow>
                <CRow>
                    <CButton
                        class="btn-outline-dark mb-1"
                        @click="clearFromTerminal"
                        v-if="sentToTerminal || terminalTransaction"
                    >
                        <font-awesome-icon
                            icon="fa fa-xmark"
                            class="clickable"
                            size="lg"
                        />
                        Clear
                    </CButton>
                </CRow>
                <CRow v-if="allowLogDownload">
                    <CButton class="btn-outline-dark" @click="downloadLog()">
                        Download Log
                    </CButton>
                </CRow>
            </CCol>
        </CRow>
        <CRow>
            <p class="text-danger" v-if="error">
                {{ this.error }}
            </p>
        </CRow>
    </CCol>
</template>

<script>
import '@adyen/adyen-web/dist/adyen.css';
import axios from 'axios';
import { SERVICE_URL } from '@/serviceResolver';
import { saveAs } from 'file-saver';
import { renderCurrency, renderStatus } from '@/tools/util';

export default {
    name: 'CardTerminal',
    components: {},
    props: ['terminal', 'transactionUuid', 'allowLogDownload'],
    beforeUnmount() {
        this.cancelAutoUpdate();
    },
    data() {
        return {
            completed: false,
            error: null,
            isEditingName: false,
            isRemoteTransaction: false,
            log: null,
            terminalTransaction: null,
            terminalSendError: null,
            transactionRefreshTimer: null,
            sendingToTerminal: false,
            sentToTerminal: false,
        };
    },
    methods: {
        cancelAutoUpdate() {
            clearInterval(this.transactionRefreshTimer);
        },
        async clearFromTerminal() {
            this.sendingToTerminal = true;
            try {
                await axios.put(
                    `${SERVICE_URL}/terminal/${this.terminal.id}/transaction`,
                );
                await this.refreshTransaction();
            } catch (e) {
                console.log(e);
                this.terminalSendError = e;
            } finally {
                this.sentToTerminal = false;
                this.sendingToTerminal = false;
            }
        },
        async downloadLog() {
            const response = await axios.get(
                `${SERVICE_URL}/terminal/${this.terminal.id}/log`,
                {
                    responseType: 'blob',
                },
            );
            saveAs(response.data, `${this.terminal.id}.log`);
        },
        focusInputField(field) {
            this.$nextTick(() => {
                this.$refs[field].$el.nextElementSibling.focus();
            });
        },
        renderCurrency,
        renderStatus,
        async refreshTransaction() {
            try {
                const transactionResponse = await axios.get(
                    `${SERVICE_URL}/terminal/${this.terminal.id}/transaction`,
                );
                this.terminalTransaction = transactionResponse.data;
                if (this.sentToTerminal && this.terminalTransaction) {
                    if (
                        this.sentToTerminal &&
                        this.transactionUuid !== this.terminalTransaction.uuid
                    ) {
                        this.failed = true;
                        this.error =
                            'Another transaction has replaced the sent transaction';
                    } else if (this.terminalTransaction.status === 'paid') {
                        this.completed = true;
                    } else if (this.terminalTransaction.status === 'rejected') {
                        this.failed = true;
                        this.error = 'Failed';
                    }
                }
            } catch (e) {
                this.error = e;
            }
        },
        async sendToTerminal() {
            this.sendingToTerminal = true;
            try {
                await axios.put(
                    `${SERVICE_URL}/terminal/${this.terminal.id}/transaction`,
                    { transactionUuid: this.transactionUuid },
                );
                await this.refreshTransaction();
                const that = this;
                this.transactionRefreshTimer = setInterval(
                    () => that.refreshTransaction(),
                    4000,
                );
            } catch (e) {
                console.log(e);
                this.terminalSendError = e;
            }
            this.sentToTerminal = true;
            this.sendingToTerminal = false;
        },
        async updateName() {
            this.isEditingName = false;
            try {
                await axios.put(`${SERVICE_URL}/terminal/${this.terminal.id}`, {
                    name: this.terminal.name,
                });
            } catch (error) {
                console.error('Error:', error);
            }
        },
    },
    mounted() {
        this.refreshTransaction();
    },
};
</script>
